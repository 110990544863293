import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['panel','filterTriggerer'];

  initialize(){
  }

  connect(){
    this.panelTarget.addEventListener('click',(e) => {
      e.stopPropagation();
    });
  }

  toggle(){
    if(this.panelTarget.classList.contains('d-none')){
      this.panelTarget.classList.remove('d-none');
      this.panelTarget.classList.add('d-flex');
      return;
    }
    this.panelTarget.classList.add('d-none');
    this.panelTarget.classList.remove('d-flex');
  }

  reset(){
    Array.prototype.slice.call(
      this.element.querySelectorAll('input:not([type="radio"]):not([type="checkbox"])')
    ).forEach((x) => {
      x.value = '';
    });

    Array.prototype.slice.call(
      this.element.querySelectorAll('select')
    ).forEach((x) => {
      $(x).val('').trigger('change');
    });

    const radios = Array.prototype.slice.call(
      this.element.querySelectorAll('input[type="radio"]')
    )
    if(radios.length > 0){
      const radios_2 = radios.reduce((group, item) => {
        const name = item.name;
        group[name] = group[name] ?? [];
        group[name].push(item);
        return group;
      }, {});
      Object.keys(radios_2).forEach((k) => {
        const elements = radios_2[k];
        elements.forEach((x) => {
          if(x.value === 'all'){
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      });
    }

    const checkboxes = Array.prototype.slice.call(
      this.element.querySelectorAll('input[type="checkbox"]')
    )
    if(checkboxes.length > 0){
      const checkboxes_2 = checkboxes.reduce((group, item) => {
        const name = item.name;
        group[name] = group[name] ?? [];
        group[name].push(item);
        return group;
      }, {});
      Object.keys(checkboxes_2).forEach((k) => {
        const elements = checkboxes_2[k];
        elements.forEach((x) => {
          x.checked = false;
        });
      });
    }

    this.filterTriggererTarget.click();
  }
}
