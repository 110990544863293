import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  initialize(){
    super.initialize();
    this.filter = document.querySelector('[data-controller="admin--index-filter"]');
    this.filterTriggerer = this.filter.querySelector('[data-admin--index-filter-target="filterTriggerer"]');
  }

  connect(){
    super.connect();
    // this.bindChangeDateFilter();
    this.bindFilter();
  }

  bindFilter(){
    this.filterTriggerer.addEventListener('click', (e) => {
      window.KTApp.blockPage();
      const params = [];

      const inputs = this.filter.querySelectorAll('input,select');
      for(let i=0 ;i<inputs.length; i++){
        if(inputs[i].type === 'radio'){
          if(inputs[i].checked){
            params.push({
              name: inputs[i].name,
              value: inputs[i].value
            });
          }
        } else if(inputs[i].tagName === 'SELECT'){
          if(inputs[i].hasAttribute('multiple')){
            const selectedValues = Array.prototype.slice.call(
              inputs[i].selectedOptions
            ).map((x) => {return x.value});
            params.push({
              name: inputs[i].name,
              value: selectedValues
            });
          } else {
            const selectedOption = inputs[i].selectedOptions[0]
            if(selectedOption){
              params.push({
                name: inputs[i].name,
                value: selectedOption.value
              });
            }
          }
        } else if(inputs[i].type === 'checkbox'){
          if(!inputs[i].checked){
            continue;
          }

          const a = params.filter((x) => {return x.name == inputs[i].name})[0]
          if(a){
            a.value.push(inputs[i].value);
          } else {
            params.push({
              name: inputs[i].name,
              value: [inputs[i].value]
            });
          }

        } else {
          params.push({
            name: inputs[i].name,
            value: inputs[i].value
          });
        }
      }

      if(params.length > 0){
        params.forEach((entry) => {
          this.datatable.setDataSourceParam(entry.name, entry.value);
          this.datatable.load();
        });
      }

      window.KTApp.unblockPage();
      this.filter.click();
    });
  }

  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 25,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `<a href="${data.show_path}" class="font-weight-bolder text-secondary">${data.index}</a>`;
        }
      },
      {
        field: 'title',
        title: 'Title',
        width: 350,
        template: function(data) {
          return `<a href="${data.show_path}" class="font-weight-bolder text-secondary">${data.title}</a>`;
        }
      },
      {
        field: 'webinar_type',
        title: 'Jenis',
        width: 55,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.webinar_type_label_html}</span>`;
        }
      },
      {
        field: 'date',
        title: 'Tanggal Webinar',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.date}</span>`;
        }
      },
      {
        field: 'capacity',
        title: 'Peserta / Kuota',
        width: 59,
        template: function(data) {
          return `
          <span class="font-weight-bolder">${data.quota_reached}/${data.capacity}</span>
          `;
        }
      },
      {
        field: 'description',
        title: 'Deskripsi',
        width: 250,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.description}</span>`;
        }
      },
      {
        field: 'speaker',
        title: 'Pembicara',
        template: function(data) {
          return `
          <span class="font-weight-bolder">${data.speaker}</span>
          `;
        }
      },
      {
        field: 'tag',
        title: 'Tags',
        template: function(data) {
          return `
            ${data.tags_html}
          `;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function(data) {
          if (data.readonly == "true"){
            return `
              <a class="btn btn-sm btn-clean btn-icon" href="${data.show_path}" title="Tampilkan">
                <i class="fa fa-eye text-primary"></i>
              </a>
            `;
          }else{
            return `
              <a class="btn btn-sm btn-clean btn-icon" href="${data.show_path}" title="Tampilkan">
                <i class="fa fa-eye text-primary"></i>
              </a>

              <a href="${data.delete_path}" data-method="delete" data-confirm="Apakah anda yakin ingin menghapus akun ini?" class="btn btn-sm btn-clean btn-icon" title="Delete">
                <i class="la la-trash text-danger"></i>
              </a>
            `;
          }
        },
      }
    ]
  }
}
