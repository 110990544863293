import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {

  args(){
    return {
      data: this.datatableData(),
      layout: this.datatableLayout(),
      sortable: this.datatableSortable(),
      pagination: this.datatablePagination(),
      search: this.datatableSearch(),
      columns: this.datatableColumns()
    }
  }

  datatableLayout(){
    return {
      scroll: true,
      footer: false,
    }
  }

  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        autoHide: false,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama',
        sortable: false,
        autoHide: false,
        overflow: 'visible',
        width: 200,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.name}</span>`;
        }
      },
      {
        field: 'date',
        title: 'Tanggal Webinar',
        sortable: false,
        autoHide: false,
        width: 200,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.date}</span>`;
        }
      },
      {
        field: 'webinar_type',
        title: 'Tiper Webinar',
        sortable: false,
        autoHide: false,
        width: 150,
        template: function(data) {
          if (data.webinar_type == 'general'){
            return `<span class="font-weight-bolder">Webinar Umum</span>`;
          }else if (data.webinar_type == 'special'){
            return `<span class="font-weight-bolder">Webinar Khusus</span>`;
          }else {
            return `<span class="font-weight-bolder">Webinar Gratis</span>`;  
          }
        }
      },
      {
        field: 'duration',
        title: 'Durasi',
        sortable: false,
        autoHide: false,
        width: 150,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.duration} Menit</span>`;
        }
      },
      {
        field: 'capacity',
        title: 'Peserta / Kuota',
        sortable: false,
        autoHide: false,
        width: 200,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.capacity}</span>`;
        }
      },
      {
        field: 'action',
        title: 'Aksi',
        sortable: false,
        width: 200,
        autoHide: false,
        template: function(data) {
          return `
            <a href="${data.show_path}" class="btn btn-sm btn-clean btn-icon" title="Detail">
              <i class="la la-eye text-primary"></i>
            </a>

            <a href="${data.delete_path}" data-method="delete" data-confirm="Apakah anda yakin ingin menghapus akun ini?" class="btn btn-sm btn-clean btn-icon" title="Delete">
              <i class="la la-trash text-danger"></i>
            </a>
          `;
        }
      },
    ]
  }
}
