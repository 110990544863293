import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama Instansi',
        sortable: false,
        width: 300,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.name}</span>`;
        }
      },
      {
        field: 'address',
        title: 'Alamat Instansi',
        sortable: false,
        width: 200,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.address}</span>`;
        }
      },
      {
        field: 'start_date',
        title: 'Tanggal Mulai Subscription',
        sortable: false,
        width: 150,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.start_date}</span>`;
        }
      },
      {
        field: 'end_date',
        title: 'Tanggal Berakhir Subscription',
        sortable: false,
        width: 150,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.end_date}</span>`;
        }
      },
      {
        field: 'max_users',
        title: 'Total Maksimal Peserta',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.max_users}</span>`;
        }
      },
      {
        field: 'status',
        title: 'Status',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.status}</span>`;
        }
      },
      {
        field: 'certificates',
        title: 'Sertifikat yang dapat di Klaim',
        sortable: false,
        width: 300,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.certificates}</span>`;
        }
      },
      {
        field: 'action',
        title: 'Aksi',
        sortable: false,
        width: 200,
        autoHide: false,
        template: function(data) {
          return `            
            <a class="btn btn-sm btn-clean btn-icon" href="${data.show_path}" title="Tampilkan">
              <i class="fa fa-eye text-primary"></i>
            </a>
          `;
        }
      },
    ]
  }
}
