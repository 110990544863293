import { Controller } from "stimulus"
import Flash from "../../packs/lib/flash"

export default class extends Controller {  
  connect() {
    this.url = this.element.dataset.url
    this.modalId = this.element.dataset.modalId
    this.modalDynamic = document.querySelector("#" + this.modalId)
    this.replacer = this.modalDynamic.querySelector('.js-dynamic-replacer')

    $(this.modalDynamic).on('shown.bs.modal', this.performRequest.bind(this))
    $(this.modalDynamic).on('hidden.bs.modal', this.refreshPage.bind(this));
    
    if (this.element.dataset.show == "true"){
      $(this.modalDynamic).modal('show')
    }
  }

  disconnect() {
    $(this.modalDynamic).off('shown.bs.modal');
    $(this.modalDynamic).off('hidden.bs.modal');  
    $(this.modalDynamic).on('shown.bs.modal', this.performRequest.bind(this))
    $(this.modalDynamic).on('hidden.bs.modal', this.refreshPage.bind(this));  
  }

  initialize() {
  }

  refreshPage(){
    // if($('.modal .show').length > 0){
    //   $('.modal').addClass('d-none');
    // }

    // setTimeout(function(){
    //   // window.KTApp.block(this.element, {});      
    //   // window.location = window.location.origin;
    //   // Turbolinks.scroll = {};          
    //   // Turbolinks.scroll['top'] = document.scrollingElement.scrollTop;
    //   // Turbolinks.visit(location.pathname);
    // }, 500);
    let tmp = document.createElement("div");
    tmp.innerHTML = ""
    this.replacer.replaceChild(tmp, this.replacer.firstElementChild);

    console.log(window.location.href);
    let excludePath = ["profiles", "dashboards", "admin", "pricing", "memberships"]
    if (!this.urlContains(window.location.href,excludePath)){
      window.history.pushState('/', 'Taxsam LMS', '/');
    }
  }
  
  performRequest(e){  
    let url = this.url
    if (e.relatedTarget && e.relatedTarget.dataset.dynamicDataId){
      url = url.replace(/\/[^\/]*$/, `/${e.relatedTarget.dataset.dynamicDataId}`)
    }
    if(!url.includes("default")){
      window.KTApp.block(this.replacer, {});
      window.Ajax.get(url, this.ajaxOptions());
    }
  }

  performManualRequest(e){
    let url = this.url
    if (e.srcElement && e.srcElement.dataset.dynamicDataId){
      url = url.replace(/\/[^\/]*$/, `/${e.srcElement.dataset.dynamicDataId}`)
    } 
    if(!url.includes("default")){
      window.KTApp.block(this.replacer, {});
      window.Ajax.get(url, this.ajaxOptions());   
    }
  }

  performCouponRequest(e){
    let url = e.dataset.url
    window.Ajax.get(url, this.ajaxOptions());
  }

  performClaimCertificateRequest(e){
    let url = e.dataset.url
    window.Ajax.get(url, this.ajaxOptions());
  }

  handleSuccess(response){
    let tmp = document.createElement("div");
    tmp.innerHTML = response
    this.replacer.replaceChild(tmp, this.replacer.firstElementChild);
  }

  handleFail(response){
    console.log(response)
    $(this.modalDynamic).modal('hide');
    
    if (this.urlContains(window.location.href, ["admin"])){
      AdminFlash.show("danger", JSON.parse(response).notice);
      window.scrollTo(0, 0);
    }else{
      Flash.show("danger", response);
    }
  }

  handleDone(responseText){
    window.KTApp.unblock(this.replacer, {});
  }

  ajaxOptions(){
    return {
      headers: [
        {
          key: 'Content-Type',
          value: 'application/html'
        }
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this)
    }
  }

  urlContains(target, pattern){
    var value = 0;
    pattern.forEach(function(word){
      value = value + target.includes(word);
    });
    return (value === 1)
  }
}
