import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {

  datatableLayout(){
    return {
      scroll: true,
      footer: false,
    }
  }

  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        autoHide: false,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama',
        sortable: false,
        autoHide: false,
        overflow: 'visible',
        width: 200,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.name}</span>`;
        }
      },
      {
        field: 'email',
        title: 'Email',
        sortable: false,
        autoHide: false,
        width: 200,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.email}</span>`;
        }
      },
      {
        field: 'role',
        title: 'Role',
        sortable: false,
        autoHide: false,
        width: 150,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.role}</span>`;
        }
      },
      {
        field: 'subscriptions',
        title: 'Status Subscribe',
        sortable: false,
        autoHide: false,
        width: 150,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.subscriptions}</span>`;
        }
      },
      {
        field: 'status',
        title: 'Status',
        sortable: false,
        autoHide: false,
        width: 200,
        template: function(data) {
          if (data.status == "registered"){
            return `<span class='label label-success label-inline mr-2 mb-2' style='height: fit-content;font-size: 13px;'>
              Terdaftar 
            </span>`
          } else {
            return `<span class='label label-warning label-inline mr-2 mb-2' style='height: fit-content;font-size: 13px;'>
              Menunggu Konfirmasi
            </span>`
          }
        }
      },
      {
        field: 'action',
        title: 'Aksi',
        sortable: false,
        width: 200,
        autoHide: false,
        template: function(data) {
          return `
            <a href="${data.delete_path}" data-method="delete" data-confirm="Apakah anda yakin ingin menghapus akun ini?" class="btn btn-sm btn-clean btn-icon" title="Delete">
              <i class="la la-trash text-danger"></i>
            </a>
          `;
        }
      },
    ]
  }
}
